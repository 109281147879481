import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import {
	getPatient,
	createPatientHealthCard,
	clearPatient,
	toggleCreatePatientHealthCardSuccessModal,
	sendPatientHealthCard,
	setTreatmentPlanOrder,
	sortTreatmentPlan,
	toggleTreatmentPlanTooth,
} from '../../actions/healthCards';

import Loader from '../../components/shared/Loader';
import Button from '../../components/shared/Button';
import Modal from '../../components/shared/Modal';

import AppointmentDetails from './AppointmentDetails';
import TeethStatus from './TeethStatus';
import Diagnoses from './Diagnoses';
import OralCavityDiagnoses from './OralCavityDiagnoses';
import Directions from './Directions';
import TreatmentPlans from './TreatmentPlans';
import BeforeAfterPhotos from './BeforeAfterPhotos';
import XRayPhotos from './XRayPhotos';
import Attachments from './Attachments';

import BelkitRodnikLogo from 'url:../../assets/rodnik_logo.jpg';
import OtherBelkitFilialLogo from 'url:../../assets/other_filial_logo.jpg';

import './styles.less';

// TODO: кажется, что пора разносить компонент на куски
class CreateHealthCard extends React.Component {
	constructor(props) {
		super(props);

		this.onCreatePatientHealthCardClick = this.onCreatePatientHealthCardClick.bind(
			this
		);

		this.onSendPatientHealthCardClick = this.onSendPatientHealthCardClick.bind(
			this
		);

		this.onGoBackClick = this.onGoBackClick.bind(this);
	}

	componentDidMount() {
		const patientId = this.getSearchParam('patientId');

		this.props.getPatient(patientId);
	}

	componentWillUnmount() {
		this.props.clearPatient();
	}

	onCreatePatientHealthCardClick() {
		const patientId = this.getSearchParam('patientId');

		this.props.createPatientHealthCard(patientId);
	}

	onSendPatientHealthCardClick() {
		const { createdPatientHealthCardName } = this.props;

		const patientId = this.getSearchParam('patientId');

		this.props.sendPatientHealthCard({
			patientId,
			healthCardName: createdPatientHealthCardName,
		});
	}

	onGoBackClick() {
		const { history } = this.props;

		return history.push('/');
	}

	getSearchParam(param) {
		const { search } = this.props.location;

		return new URLSearchParams(search).get(param);
	}

	render() {
		const {
			isGettingPatient,
			patient,
			isCreatingPatientHealthCard,
			isCreatedPatientHealthCard,
			isSendingPatientHealthCard,
			isCreatePatientHealthCardSuccessModalShown,
			isSendPatientHealthCardSuccessModalShown,
		} = this.props;

		const patientId = this.getSearchParam('patientId');

		if (!patientId) {
			return <Redirect to="/" />;
		}

		if (isGettingPatient) {
			return <Loader className="absolute absolute--center" />;
		}

		const logo =
			patient.departmentId === 6 ? BelkitRodnikLogo : OtherBelkitFilialLogo;

		const link =
			patient.departmentId === 6 ? 'https://belkitdeti.ru' : 'https://belkit.ru';

		// TODO: мб поменять структуру patient
		return (
			<>
				<div className="container">
					<div className="health-card">
						<div>
							<img className="health-card__logo" src={logo} alt="Logo" />
							<ul>
								<li>
									<a href={link} target="_blank" rel="noreferrer">
										{link}
									</a>
								</li>
								<li>
									<a href="tel:+7340004" target="_blank" rel="noreferrer">
										734-000-4
									</a>
								</li>
							</ul>
						</div>
						<h2 className="health-card__title">Карта здоровья</h2>
						<AppointmentDetails patient={patient} />
						<TeethStatus teeth={patient.teeth} />
						<Diagnoses diagnoses={patient.diagnoses} />
						<OralCavityDiagnoses oralCavityDiagnoses={patient.oralCavityDiagnoses} />
						<Directions directions={patient.directions} />
						<TreatmentPlans
							treatmentPlans={patient.treatmentPlans}
							onTreatmentPlanSortHandler={this.props.sortTreatmentPlan}
							onTreatmentPlanSetOrderHandler={this.props.setTreatmentPlanOrder}
							onTreatmentPlanToggleToothHandler={this.props.toggleTreatmentPlanTooth}
						/>
						<BeforeAfterPhotos
							patientId={patientId}
							photos={patient.beforeAfterPhotos}
						/>
						<XRayPhotos patientId={patientId} photos={patient.xRayPhotos} />
						<Attachments />
						<div className="flex" data-html2canvas-ignore="true">
							<Button
								className="button--secondary health-card__button health-card__button--back"
								onClick={this.onGoBackClick}
							>
								Назад
							</Button>
							{!isCreatedPatientHealthCard && (
								<Button
									className="button--primary health-card__button health-card__button--create"
									isLoading={isCreatingPatientHealthCard}
									onClick={this.onCreatePatientHealthCardClick}
								>
									Сохранить
								</Button>
							)}
							{patient.email && isCreatedPatientHealthCard && (
								<Button
									className="button--primary health-card__button health-card__button--send"
									isLoading={isSendingPatientHealthCard}
									onClick={this.onSendPatientHealthCardClick}
								>
									Отправить
								</Button>
							)}
						</div>
						<div className="health-card__clarification">
							<p>
								<sup>*</sup>Оплата лечения пациентом происходит по прейскуранту клиники
								на день оказания услуг.
							</p>
							<p>
								<sup>*</sup>План лечения может быть изменен в случае необходимости
								оказания дополнительных медицинских услуг в связи с изменением
								клинической ситуации и состояния здоровья пациента, с предварительным
								уведомлением пациента.
							</p>
						</div>
					</div>
				</div>
				{isCreatePatientHealthCardSuccessModalShown && (
					<Modal
						title="Карта успешно сохранена!"
						toggleModal={this.props.toggleCreatePatientHealthCardSuccessModal}
					/>
				)}
				{isSendPatientHealthCardSuccessModalShown && (
					<Modal
						title="Карта успешно отправлена!"
						toggleModal={this.onGoBackClick}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	patient: state.healthCards.patient,
	isGettingPatient: state.healthCards.isGettingPatient,

	isCreatingPatientHealthCard: state.healthCards.isCreatingPatientHealthCard,
	isCreatedPatientHealthCard: state.healthCards.isCreatedPatientHealthCard,
	isCreatePatientHealthCardSuccessModalShown:
		state.healthCards.isCreatePatientHealthCardSuccessModalShown,
	createdPatientHealthCardName: state.healthCards.createdPatientHealthCardName,

	isSendingPatientHealthCard: state.healthCards.isSendingPatientHealthCard,
	isSendPatientHealthCardSuccessModalShown:
		state.healthCards.isSendPatientHealthCardSuccessModalShown,
});

const mapDispatchToProps = (dispatch) => ({
	getPatient: (id) => dispatch(getPatient(id)),
	clearPatient: () => dispatch(clearPatient()),

	createPatientHealthCard: (patientId) =>
		dispatch(createPatientHealthCard(patientId)),
	toggleCreatePatientHealthCardSuccessModal: () =>
		dispatch(toggleCreatePatientHealthCardSuccessModal()),

	sendPatientHealthCard: (data) => dispatch(sendPatientHealthCard(data)),

	setTreatmentPlanOrder: (data) => dispatch(setTreatmentPlanOrder(data)),
	sortTreatmentPlan: (data) => dispatch(sortTreatmentPlan(data)),
	toggleTreatmentPlanTooth: (data) => dispatch(toggleTreatmentPlanTooth(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CreateHealthCard));
