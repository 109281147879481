import React from 'react';
import classNames from 'classnames';

import Loader from '../Loader';

import './styles.less';

const Button = (props) => {
	const { children, className, isLoading, disabled } = props;

	return (
		<button
			className={classNames('button', className)}
			type="button"
			onClick={props.onClick}
			disabled={disabled}
		>
			{isLoading && <Loader />}
			{!isLoading && children}
		</button>
	);
};

export default Button;
