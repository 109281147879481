import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import store from './store';
import './helpers/logger';

import Home from './pages/Home';
import CreateHealthCard from './pages/CreateHealthCard';
import Login from './pages/Login';

import PrivateRoute from './components/shared/PrivateRoute';

import './styles.less';

const App = () => (
	<Router>
		<Switch>
			<PrivateRoute exact path="/">
				<Home />
			</PrivateRoute>
			<PrivateRoute path="/сreate-health-card">
				<CreateHealthCard />
			</PrivateRoute>
			<Route path="/login">
				<Login />
			</Route>
		</Switch>
	</Router>
);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('app')
);
