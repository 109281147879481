import {
	SET_PATIENT_ID,
	GET_PATIENT_REQUEST,
	GET_PATIENT_SUCCESS,
	GET_PATIENT_FAIL,
	CHOOSE_BEFORE_AFTER_PHOTO,
	CHOOSE_X_RAY_PHOTO,
	GET_PATIENT_HEALTH_CARDS_REQUEST,
	GET_PATIENT_HEALTH_CARDS_SUCCESS,
	GET_PATIENT_HEALTH_CARDS_FAIL,
	CREATE_PATIENT_HEALTH_CARD_REQUEST,
	CREATE_PATIENT_HEALTH_CARD_SUCCESS,
	CREATE_PATIENT_HEALTH_CARD_FAIL,
	SORT_PATIENT_HEALTH_CARDS,
	TOGGLE_CREATE_PATIENT_HEALTH_CARD_SUCCESS_MODAL,
	TOGGLE_SEND_PATIENT_HEALTH_CARD_SUCCESS_MODAL,
	CLEAR_PATIENT,
	SEND_PATIENT_HEALTH_CARD_REQUEST,
	SEND_PATIENT_HEALTH_CARD_SUCCESS,
	SEND_PATIENT_HEALTH_CARD_FAIL,
	SET_TREATMENT_PLAN_ORDER,
	SORT_TREATMENT_PLAN,
	TOGGLE_TREATMENT_PLAN_TOOTH,
} from '../actions/healthCards';

const initialState = {
	isGettingPatientHealthCards: false,
	isGotPatientHealthCards: false,
	patientId: '',
	patientHealthCards: [],
	patientHealthCardsSortingDirection: 'DESC',

	isGettingPatient: false,
	patient: {},

	isCreatingPatientHealthCard: false,
	isCreatedPatientHealthCard: false,
	createdPatientHealthCardName: '',
	isCreatePatientHealthCardSuccessModalShown: false,

	isSendPatientHealthCardSuccessModalShown: false,
	isSendingPatientHealthCard: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_PATIENT_ID:
			return {
				...state,
				patientId: action.payload,
			};
		case GET_PATIENT_REQUEST:
		case GET_PATIENT_FAIL:
			return {
				...state,
				isGettingPatient: true,
			};
		case GET_PATIENT_SUCCESS:
			return {
				...state,
				patient: action.payload,
				isGettingPatient: false,
			};

		case CHOOSE_BEFORE_AFTER_PHOTO:
			// TODO: подумать над реализацией
			return {
				...state,
				patient: {
					...state.patient,
					beforeAfterPhotos: state.patient.beforeAfterPhotos.map((photo) => {
						if (photo.ino === action.payload) {
							return {
								...photo,
								checked: !photo.checked,
							};
						}

						return photo;
					}),
				},
			};
		case CHOOSE_X_RAY_PHOTO:
			return {
				...state,
				patient: {
					...state.patient,
					xRayPhotos: state.patient.xRayPhotos.map((photo) => {
						if (photo.ino === action.payload) {
							return {
								...photo,
								checked: !photo.checked,
							};
						}

						return photo;
					}),
				},
			};
		case GET_PATIENT_HEALTH_CARDS_REQUEST:
			return {
				...state,
				isGettingPatientHealthCards: true,
			};
		case GET_PATIENT_HEALTH_CARDS_SUCCESS:
			return {
				...state,
				isGettingPatientHealthCards: false,
				isGotPatientHealthCards: true,
				patientHealthCards: action.payload,
			};
		case GET_PATIENT_HEALTH_CARDS_FAIL:
			return {
				...state,
				isGettingPatientHealthCards: false,
			};

		case SORT_PATIENT_HEALTH_CARDS:
			return {
				...state,
				patientHealthCardsSortingDirection:
					state.patientHealthCardsSortingDirection === 'ASC' ? 'DESC' : 'ASC',
			};

		case CREATE_PATIENT_HEALTH_CARD_REQUEST:
			return {
				...state,
				isCreatingPatientHealthCard: true,
			};
		case CREATE_PATIENT_HEALTH_CARD_SUCCESS:
			return {
				...state,
				isCreatingPatientHealthCard: false,
				isCreatedPatientHealthCard: true,
				isCreatePatientHealthCardSuccessModalShown: true,
				createdPatientHealthCardName: action.payload,
			};
		case CREATE_PATIENT_HEALTH_CARD_FAIL:
			return {
				...state,
				isCreatingPatientHealthCard: false,
			};

		case TOGGLE_CREATE_PATIENT_HEALTH_CARD_SUCCESS_MODAL:
			return {
				...state,
				isCreatePatientHealthCardSuccessModalShown: !state.isCreatePatientHealthCardSuccessModalShown,
			};

		case SEND_PATIENT_HEALTH_CARD_REQUEST:
			return {
				...state,
				isSendingPatientHealthCard: true,
			};
		case SEND_PATIENT_HEALTH_CARD_SUCCESS:
			return {
				...state,
				isSendingPatientHealthCard: false,
				isSendPatientHealthCardSuccessModalShown: true,
			};
		case SEND_PATIENT_HEALTH_CARD_FAIL:
			return {
				...state,
				isSendingPatientHealthCard: false,
			};

		case TOGGLE_SEND_PATIENT_HEALTH_CARD_SUCCESS_MODAL:
			return {
				...state,
				isSendPatientHealthCardSuccessModalShown: !state.isSendPatientHealthCardSuccessModalShown,
			};

		case SET_TREATMENT_PLAN_ORDER:
			return {
				...state,
				patient: {
					...state.patient,
					treatmentPlans: state.patient.treatmentPlans.map((treatmentPlan) => {
						if (treatmentPlan.id === action.payload.id) {
							return {
								...treatmentPlan,
								teeth: treatmentPlan.teeth.map((tooth) => {
									if (tooth.id === action.payload.toothId) {
										return {
											...tooth,
											sort: action.payload.sort,
										};
									}

									return tooth;
								}),
							};
						}

						return treatmentPlan;
					}),
				},
			};

		case SORT_TREATMENT_PLAN:
			return {
				...state,
				patient: {
					...state.patient,
					treatmentPlans: state.patient.treatmentPlans.map((treatmentPlan) => {
						if (treatmentPlan.id === action.payload) {
							return {
								...treatmentPlan,
								teeth: [...treatmentPlan.teeth].sort((a, b) => a.sort - b.sort),
							};
						}

						return treatmentPlan;
					}),
				},
			};

		case TOGGLE_TREATMENT_PLAN_TOOTH:
			return {
				...state,
				patient: {
					...state.patient,
					treatmentPlans: state.patient.treatmentPlans.map((treatmentPlan) => {
						if (treatmentPlan.id === action.payload.id) {
							return {
								...treatmentPlan,
								teeth: treatmentPlan.teeth.map((tooth) => {
									if (tooth.id === action.payload.toothId) {
										return {
											...tooth,
											checked: !tooth.checked,
										};
									}

									return tooth;
								}),
							};
						}

						return treatmentPlan;
					}),
				},
			};

		case CLEAR_PATIENT:
			return initialState;
		default:
			return state;
	}
};
