import React from 'react';
import classnames from 'classnames';

import getHealthCardDownloadLink from '../../../helpers/getHealthCardDownloadLink';

import './styles.less';

// TODO: придумать что-то с сортировкой
class HealthCards extends React.Component {
	sortByName() {
		const { patientHealthCardsSortingDirection } = this.props;

		return (a, b) => {
			if (patientHealthCardsSortingDirection === 'ASC') {
				return a.name.localeCompare(b.name);
			}

			return b.name.localeCompare(a.name);
		};
	}

	renderSortingIcon() {
		const { patientHealthCardsSortingDirection } = this.props;

		const arePatientHealthCardsSortedByAsc =
			patientHealthCardsSortingDirection === 'ASC';

		// TODO: не нравится, мб просто FA заюзать
		return (
			<span
				className={classnames('icon icon--arrow', {
					reversed: arePatientHealthCardsSortedByAsc,
				})}
			/>
		);
	}

	renderRows() {
		const { patientHealthCards } = this.props;

		return [...patientHealthCards]
			.sort(this.sortByName())
			.map(this.renderRow.bind(this));
	}

	renderRow({ name, birthtime }) {
		const { patientId } = this.props;

		const downloadLink = getHealthCardDownloadLink({
			patientId,
			cardName: name,
		});

		return (
			<tr className="health-cards__card card" key={birthtime}>
				<td>
					<a
						className="card__name"
						href={downloadLink}
						target="_blank"
						rel="noreferrer"
					>
						{name}
					</a>
				</td>
				<td>
					<span className="card__date">
						{new Date(birthtime).toLocaleDateString()}
					</span>
				</td>
			</tr>
		);
	}

	render() {
		const { isGotPatientHealthCards, patientHealthCards } = this.props;

		if (!isGotPatientHealthCards) {
			return (
				<p>Найдите клиента по идентификатору, чтобы увидеть его карты здоровья.</p>
			);
		}

		if (patientHealthCards === null) {
			return <p>Такого пациента не существует.</p>;
		}

		if (!patientHealthCards.length) {
			return <p>У этого пациента нет карт здоровья.</p>;
		}

		return (
			<table className="health-cards__table">
				<thead>
					<tr>
						<th className="selectable" onClick={this.props.sortPatientHealthCards}>
							Название
							{this.renderSortingIcon()}
						</th>
						<th>Дата создания</th>
					</tr>
				</thead>
				<tbody>{this.renderRows()}</tbody>
			</table>
		);
	}
}

export default HealthCards;
