import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { setFieldValue, makeAuthorize, getDoctors } from '../../actions/auth';

import mapDoctorsForSelect from '../../helpers/mapDoctorsForSelect';

import Input from '../../components/shared/Input';
import Button from '../../components/shared/Button';
import Select from '../../components/shared/Select';
import Loader from '../../components/shared/Loader';

import './styles.less';

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		this.props.getDoctors();
	}

	onChange({ name, value }) {
		this.props.setFieldValue({ [name]: value });
	}

	onClick() {
		const { login, password } = this.props;

		this.props.makeAuthorize({
			login,
			password,
		});
	}

	render() {
		const {
			login,
			password,
			isAuthorizing,
			isAuthorized,
			doesAuthorizingErrorOccur,
			doctors,
			isGettingDoctors,
		} = this.props;

		if (isAuthorized) {
			return <Redirect to="/" />;
		}

		if (isGettingDoctors) {
			return <Loader className="absolute absolute--center" />;
		}

		return (
			<div className="login">
				<h1 className="login__title">DentReader</h1>
				<form className="login__form">
					<Select
						className="login__select"
						name="login"
						value={login}
						options={mapDoctorsForSelect(doctors)}
						onChange={this.onChange}
					/>
					<Input
						className="login__input"
						label="Пароль"
						type="password"
						value={password}
						name="password"
						onChange={this.onChange}
					/>
					<Button
						className="button--primary"
						isLoading={isAuthorizing}
						onClick={this.onClick}
					>
						Войти
					</Button>
					{doesAuthorizingErrorOccur && (
						<span className="login__fail">Такого доктора не существует!</span>
					)}
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	login: state.auth.login,
	password: state.auth.password,
	isAuthorizing: state.auth.isAuthorizing,
	isAuthorized: state.auth.isAuthorized,
	doesAuthorizingErrorOccur: state.auth.doesAuthorizingErrorOccur,
	doctors: state.auth.doctors,
	isGettingDoctors: state.auth.isGettingDoctors,
});

const mapDispatchToProps = (dispatch) => ({
	setFieldValue: (data) => dispatch(setFieldValue(data)),
	makeAuthorize: (data) => dispatch(makeAuthorize(data)),
	getDoctors: () => dispatch(getDoctors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
