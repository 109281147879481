import React from 'react';

import ignoreUncheckedElementInCanvas from '../../../helpers/ignoreUncheckedElementInCanvas';

import './styles.less';

// TODO: объединить таблицы
class Directions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isBlockIgnoredInCanvas: false,
		};
	}

	onVisibilitySwitcherChangeHandler() {
		const { isBlockIgnoredInCanvas } = this.state;

		this.setState({
			isBlockIgnoredInCanvas: !isBlockIgnoredInCanvas,
		});
	}

	render() {
		const { directions = [] } = this.props;

		if (!directions.length) {
			return (
				<div
					className="health-card__section directions"
					data-html2canvas-ignore="true"
				>
					<h3>Направления</h3>
					<p>У этого пациента нет направлений.</p>
				</div>
			);
		}

		const { isBlockIgnoredInCanvas } = this.state;

		return (
			<div
				className="health-card__section directions"
				{...ignoreUncheckedElementInCanvas(isBlockIgnoredInCanvas)}
			>
				<label>
					Направления
					<input
						type="checkbox"
						onChange={() => this.onVisibilitySwitcherChangeHandler()}
					/>
				</label>
				<table className="diagnoses__table">
					<thead>
						<tr>
							<th>Дата направления</th>
							<th>В отделение</th>
							<th>Код DS</th>
							<th>Предварительный диагноз</th>
						</tr>
					</thead>
					<tbody>
						{directions.map((direction) => (
							<tr>
								<td>{new Date(direction.treatdate).toLocaleDateString()}</td>
								<td>{direction.depname}</td>
								<td>{direction.mkbcode}</td>
								<td className="left-align">{direction.dgname}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default Directions;
