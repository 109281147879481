import React from 'react';

import './styles.less';

const Thumb = (props) => {
	const { preview } = props;

	return (
		<div className="attachments__thumb">
			<img src={preview} alt="Thumb" />
		</div>
	);
};

export default Thumb;
