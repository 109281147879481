import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { chooseBeforeAfterPhoto } from '../../../actions/healthCards';

import Checkmark from '../../../components/shared/Checkmark';

import getBeforeAfterPhotoLink from '../../../helpers/getBeforeAfterPhotoLink';
import ignoreUncheckedElementInCanvas from '../../../helpers/ignoreUncheckedElementInCanvas';

import './styles.less';

class BeforeAfterPhotos extends React.Component {
	constructor(props) {
		super(props);

		this.onPhotoClickHandler = this.onPhotoClickHandler.bind(this);
	}

	onPhotoClickHandler(id) {
		this.props.chooseBeforeAfterPhoto(id);
	}

	mapBeforeAfterPhoto({ ino, name, checked }) {
		const { patientId } = this.props;

		const link = getBeforeAfterPhotoLink({
			patientId,
			photoName: name,
		});

		// TODO: вынести в компонент и пробрасывать через пропсы
		return (
			<div className={classNames('ba-photo', { checked })}>
				<div
					onClick={() => this.onPhotoClickHandler(ino)}
					{...ignoreUncheckedElementInCanvas(checked)}
				>
					<img key={ino} src={link} alt="" />
				</div>
				{checked && <Checkmark className="ba-photo__checkmark" />}
			</div>
		);
	}

	render() {
		const { photos = [] } = this.props;

		const choosedPhotos = photos.filter(({ checked }) => checked);

		return (
			<div
				className="health-card__section ba-photos"
				{...ignoreUncheckedElementInCanvas(choosedPhotos.length)}
			>
				<h3>Фото (до / после)</h3>
				{!photos.length && <p>У этого пациента нет фотографий.</p>}
				{photos.map(this.mapBeforeAfterPhoto.bind(this))}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	chooseBeforeAfterPhoto: (data) => dispatch(chooseBeforeAfterPhoto(data)),
});

export default connect(null, mapDispatchToProps)(BeforeAfterPhotos);
