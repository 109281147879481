import React from 'react';

import ignoreUncheckedElementInCanvas from '../../../helpers/ignoreUncheckedElementInCanvas';

import './styles.less';

class OralCavityDiagnoses extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isBlockIgnoredInCanvas: false,
		};
	}

	onVisibilitySwitcherChangeHandler() {
		const { isBlockIgnoredInCanvas } = this.state;

		this.setState({
			isBlockIgnoredInCanvas: !isBlockIgnoredInCanvas,
		});
	}

	render() {
		const { oralCavityDiagnoses = [] } = this.props;

		if (!oralCavityDiagnoses.length) {
			return (
				<div
					className="health-card__section diagnoses"
					data-html2canvas-ignore="true"
				>
					<h3>Предварительные диагнозы</h3>
					<p>У этого пациента нет предварительных диагнозов.</p>
				</div>
			);
		}

		const { isBlockIgnoredInCanvas } = this.state;

		return (
			<div
				className="health-card__section diagnoses"
				{...ignoreUncheckedElementInCanvas(isBlockIgnoredInCanvas)}
			>
				<label>
					Предварительные диагнозы{' '}
					<input
						type="checkbox"
						onChange={() => this.onVisibilitySwitcherChangeHandler()}
					/>
				</label>
				<table className="diagnoses__table">
					<thead>
						<tr>
							<th>Пол. рта</th>
							<th>Код DS</th>
							<th>Предварительный диагноз</th>
							<th>Статус</th>
							<th>Этап</th>
							<th>Время</th>
						</tr>
					</thead>
					<tbody>
						{oralCavityDiagnoses.map((diagnosis) => (
							<tr>
								<td>{diagnosis.cavity}</td>
								<td>{diagnosis.mkbcode}</td>
								<td className="left-align">{diagnosis.name}</td>
								<td>{diagnosis.status}</td>
								<td>{diagnosis.stage}</td>
								<td>{diagnosis.date}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default OralCavityDiagnoses;
