import React from 'react';
import classnames from 'classnames';

import ignoreUncheckedElementInCanvas from '../../../helpers/ignoreUncheckedElementInCanvas';

import './styles.less';

const legend = [
	{ code: 'C', value: 'кариес' },
	{ code: 'П', value: 'пломба' },
	{ code: 'KL', value: 'клиновидный дефект' },
	{ code: 'Pt', value: 'пульпит' },
	{ code: 'Л', value: 'литок (часть протеза между кор.)' },
	{ code: 'CP', value: 'кариес-пломба' },
	{ code: 'В', value: 'вкладка' },
	{ code: 'Pr', value: 'периодонтит' },
	{ code: 'R', value: 'корень' },
	{ code: 'П/С', value: 'вторичный кариес' },
	{ code: 'K', value: 'коронка искусственная' },
	{ code: 'I, II, III, IIII', value: 'подвижность (степень)' },
	{ code: 'O', value: 'отсутствует' },
	{ code: 'Ф', value: 'фасетка' },
];

class TeethStatus extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isBlockIgnoredInCanvas: false,
		};
	}

	onVisibilitySwitcherChangeHandler() {
		const { isBlockIgnoredInCanvas } = this.state;

		this.setState({
			isBlockIgnoredInCanvas: !isBlockIgnoredInCanvas,
		});
	}

	render() {
		const { teeth = {} } = this.props;

		if (!Object.keys(teeth).length) {
			return (
				<div className="health-card__section" data-html2canvas-ignore="true">
					<h3>Статусы зубов, состояния поверхностей и корней</h3>
					<p>У этого пациента нет предварительных диагнозов.</p>
				</div>
			);
		}

		// TODO: придумать что-то с кодом
		const filteredTeethId = Object.keys(teeth).filter((tooth) =>
			tooth.includes('name')
		);

		const halfwayThrough = Math.floor(filteredTeethId.length / 2);

		const quarterThrough = halfwayThrough / 2;

		const firstJawId = filteredTeethId.slice(0, halfwayThrough);

		const secondJawId = filteredTeethId.slice(
			halfwayThrough,
			filteredTeethId.length
		);

		// ----

		const filteredTeethStatus = Object.keys(teeth).filter((tooth) =>
			tooth.includes('value')
		);

		const firstJawStatus = filteredTeethStatus.slice(0, halfwayThrough);

		const secondJawStatus = filteredTeethStatus.slice(
			halfwayThrough,
			filteredTeethStatus.length
		);

		const { isBlockIgnoredInCanvas } = this.state;

		return (
			<div
				className="health-card__section"
				{...ignoreUncheckedElementInCanvas(isBlockIgnoredInCanvas)}
			>
				<label>
					Статусы зубов, состояния поверхностей и корней
					<input
						type="checkbox"
						onChange={() => this.onVisibilitySwitcherChangeHandler()}
					/>
				</label>
				<div className="teeth-status">
					<ul className="teeth-status__legend">
						{legend.map((item) => (
							<li key={item.code}>
								<b>{item.code}</b> - {item.value}
							</li>
						))}
					</ul>
					<table className="teeth-status__table">
						<tbody>
							<tr>
								{firstJawStatus.map((tooth, toothIndex) => (
									<td
										key={`${tooth}-status`}
										className={classnames({
											'right-bordered': toothIndex + 1 === quarterThrough,
										})}
									>
										{teeth[tooth]}
									</td>
								))}
							</tr>
							<tr>
								{firstJawId.map((tooth, toothIndex) => (
									<td
										key={tooth}
										className={classnames({
											'right-bordered': toothIndex + 1 === quarterThrough,
										})}
									>
										{teeth[tooth]}
									</td>
								))}
							</tr>
							<tr>
								{secondJawId.map((tooth, toothIndex) => (
									<td
										key={tooth}
										className={classnames({
											'right-bordered': toothIndex + 1 === quarterThrough,
										})}
									>
										{teeth[tooth]}
									</td>
								))}
							</tr>
							<tr>
								{secondJawStatus.map((tooth, toothIndex) => (
									<td
										key={`${tooth}-status`}
										className={classnames({
											'right-bordered': toothIndex + 1 === quarterThrough,
										})}
									>
										{teeth[tooth]}
									</td>
								))}
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default TeethStatus;
