import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export default () => (
	html2canvas(document.body, {
		scale: 1,
		useCORS: true,
		scrollX: 0,
    	scrollY: 0
	}).then((canvas) => {
		const { width, height } = canvas;
		const img = canvas.toDataURL('image/jpeg');

		const pdf = new jsPDF({
			unit: 'px',
			format: [width, height],
			hotfixes: ['px_scaling'],
			floatPrecision: 'smart',
		});

		pdf.addImage(img, 'JPEG', 0, 0, width, height);

		return pdf.output('blob');
	})
);