import React from 'react';
import classNames from 'classnames';

import './styles.less';

class Select extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	onChange({ target }) {
		const { name } = this.props;

		this.props.onChange({
			name,
			value: target.value,
		});
	}

	render() {
		const { options, className, value } = this.props;

		return (
			<select
				value={value}
				className={classNames('select', className)}
				onChange={this.onChange}
			>
				<option value="" disabled hidden>
					Выберите врача
				</option>
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
		);
	}
}

export default Select;
