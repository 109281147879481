import { API_URL } from '../constants/global';

window.onerror = (message, url, line) => {
	const options = {
		method: 'POST',
		body: JSON.stringify({
			data: {
				message,
				url,
				line,
			}
		}),
		headers: {
			'Content-Type': 'application/json',
		},
	};

	fetch(`${API_URL}/log`, options)
		.then((response) => response.json())
		.then((response) => {
			if (!response.success) {
				alert(error);
			}
		})
		.catch(() => alert(error));
};
