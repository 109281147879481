import React from 'react';

const AppointmentDetails = (props) => {
	const { name, id, date, doctor, department } = props.patient;

	return (
		<div className="health-card__section appointment-details">
			<ul>
				<li>
					<b>ФИО пациента:</b> <span>{name}</span>
				</li>
				<li>
					<b>ИНП:</b> <span>{id}</span>
				</li>
				<li>
					<b>Дата:</b> <span>{new Date(date).toLocaleDateString()}</span>
				</li>
				<li>
					<b>Врач:</b> <span>{doctor}</span>
				</li>
				<li>
					<b>Филиал:</b> <span>{department}</span>
				</li>
			</ul>
		</div>
	);
};

export default AppointmentDetails;
