import {
	SET_FIELD_VALUE,
	MAKE_AUTHORIZE_FAIL,
	MAKE_AUTHORIZE_REQUEST,
	MAKE_AUTHORIZE_SUCCESS,
	GET_DOCTORS_REQUEST,
	GET_DOCTORS_SUCCESS,
	GET_DOCTORS_FAIL,
} from '../actions/auth';

const initialState = {
	isAuthorizing: false,
	isAuthorized: localStorage.getItem('isAuthorized') === 'true',
	login: '',
	password: '',
	doesAuthorizingErrorOccur: false,
	doctors: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_DOCTORS_REQUEST:
			return {
				...state,
				isGettingDoctors: true,
			};
		case GET_DOCTORS_FAIL:
			return {
				...state,
				isGettingDoctors: false,
			};
		case GET_DOCTORS_SUCCESS:
			return {
				...state,
				isGettingDoctors: false,
				doctors: action.payload,
			};

		case SET_FIELD_VALUE:
			return {
				...state,
				...action.payload,
			};

		case MAKE_AUTHORIZE_REQUEST:
			return {
				...state,
				isAuthorizing: true,
				doesAuthorizingErrorOccur: false,
			};
		case MAKE_AUTHORIZE_SUCCESS:
			localStorage.setItem('isAuthorized', true);
			localStorage.setItem('doctor', JSON.stringify(action.payload));

			return {
				...state,
				isAuthorizing: false,
				isAuthorized: true,
			};
		case MAKE_AUTHORIZE_FAIL:
			return {
				...state,
				isAuthorizing: false,
				isAuthorized: false,
				doesAuthorizingErrorOccur: true,
			};
		default:
			return state;
	}
};
