import { API_URL } from '../constants/global';

export const GET_DOCTORS_REQUEST = 'GET_DOCTORS_REQUEST';
export const GET_DOCTORS_SUCCESS = 'GET_DOCTORS_SUCCESS';
export const GET_DOCTORS_FAIL = 'GET_DOCTORS_FAIL';

export const MAKE_AUTHORIZE_REQUEST = 'MAKE_AUTHORIZE_REQUEST';
export const MAKE_AUTHORIZE_SUCCESS = 'MAKE_AUTHORIZE_SUCCESS';
export const MAKE_AUTHORIZE_FAIL = 'MAKE_AUTHORIZE_FAIL';

export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';

const getDoctorsRequest = () => ({
	type: GET_DOCTORS_REQUEST,
});

const getDoctorsSuccess = (data) => ({
	type: GET_DOCTORS_SUCCESS,
	payload: data,
});

const getDoctorsFail = () => ({
	type: GET_DOCTORS_FAIL,
});

export const getDoctors = () => (dispatch) => {
	dispatch(getDoctorsRequest());

	const options = {
		method: 'GET',
	};

	fetch(`${API_URL}/doctors`, options)
		.then((response) => response.json())
		.then((response) => {
			if (response.success) {
				return setTimeout(() => dispatch(getDoctorsSuccess(response.data)), 1500);
			}

			return dispatch(getDoctorsFail());
		})
		.catch(() => dispatch(getDoctorsFail()));
};

export const setFieldValue = (data) => ({
	type: SET_FIELD_VALUE,
	payload: data,
});

const makeAuthorizeRequest = () => ({
	type: MAKE_AUTHORIZE_REQUEST,
});

const makeAuthorizeSuccess = (data) => ({
	type: MAKE_AUTHORIZE_SUCCESS,
	payload: data,
});

const makeAuthorizeFail = () => ({
	type: MAKE_AUTHORIZE_FAIL,
});

export const makeAuthorize = (data) => (dispatch) => {
	dispatch(makeAuthorizeRequest());

	const options = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
	};

	fetch(`${API_URL}/auth/login`, options)
		.then((response) => response.json())
		.then((response) => {
			if (response.success) {
				return dispatch(makeAuthorizeSuccess(response.data));
			}

			return dispatch(makeAuthorizeFail());
		})
		.catch(() => dispatch(makeAuthorizeFail()));
};
