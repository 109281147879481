import React from 'react';

import Thumb from './Thumb';

const Thumbs = (props) => {
	const { attachments } = props;

	return (
		<div className="attachments__thumbs">
			{attachments.map(({ name, preview }) => (
				<Thumb key={name} preview={preview} />
			))}
		</div>
	);
};

export default Thumbs;
