import React from 'react';
import classNames from 'classnames';

import './styles.less';

class Input extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	onChange({ target }) {
		this.props.onChange({
			name: target.name,
			value: target.value,
		});
	}

	render() {
		const { label, className, value, type, name, placeholder } = this.props;

		return (
			<div className={classNames('input', className)}>
				<label className="input__label">
					{label}
					<input
						type={type}
						placeholder={placeholder}
						value={value}
						name={name}
						onChange={this.onChange}
					/>
				</label>
			</div>
		);
	}
}

export default Input;
