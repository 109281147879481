import React from 'react';

import Button from '../Button';

import './styles.less';

// TODO: везде проверить нейминг
// TODO: мейби иконки тоже вынести как компоненты
class Modal extends React.Component {
	constructor(props) {
		super(props);

		this.modalRef = null;
		this.onDocumentClick = this.onDocumentClick.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.onDocumentClick, false);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onDocumentClick, false);
	}

	onDocumentClick({ target }) {
		if (target.isEqualNode(this.modalRef)) {
			this.props.toggleModal();
		}
	}

	render() {
		const { title } = this.props;

		return (
			<div className="modal" ref={(node) => (this.modalRef = node)}>
				<div className="modal__content">
					<h2 className="modal__title">{title}</h2>
					<Button
						className="button--primary modal__close"
						onClick={this.props.toggleModal}
					>
						ОК
					</Button>
				</div>
			</div>
		);
	}
}

export default Modal;
