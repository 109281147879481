import { API_URL } from '../constants/global';
import getPdfFromCanvas from '../helpers/getPdfFromCanvas';

export const SET_PATIENT_ID = 'SET_PATIENT_ID';

export const GET_PATIENT_REQUEST = 'GET_PATIENT_REQUEST';
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_FAIL = 'GET_PATIENT_FAIL';

export const GET_PATIENT_HEALTH_CARDS_REQUEST =
	'GET_PATIENT_HEALTH_CARDS_REQUEST';
export const GET_PATIENT_HEALTH_CARDS_SUCCESS =
	'GET_PATIENT_HEALTH_CARDS_SUCCESS';
export const GET_PATIENT_HEALTH_CARDS_FAIL = 'GET_PATIENT_HEALTH_CARDS_FAIL';

export const SET_TREATMENT_PLAN_ORDER = 'SET_TREATMENT_PLAN_ORDER';

export const SORT_TREATMENT_PLAN = 'SORT_TREATMENT_PLAN';

export const TOGGLE_TREATMENT_PLAN_TOOTH = 'TOGGLE_TREATMENT_PLAN_TOOTH';

export const CHOOSE_BEFORE_AFTER_PHOTO = 'CHOOSE_BEFORE_AFTER_PHOTO';
export const CHOOSE_X_RAY_PHOTO = 'CHOOSE_X_RAY_PHOTO';

export const CREATE_PATIENT_HEALTH_CARD_REQUEST =
	'CREATE_PATIENT_HEALTH_CARD_REQUEST';
export const CREATE_PATIENT_HEALTH_CARD_SUCCESS =
	'CREATE_PATIENT_HEALTH_CARD_SUCCESS';
export const CREATE_PATIENT_HEALTH_CARD_FAIL =
	'CREATE_PATIENT_HEALTH_CARD_FAIL';

export const SORT_PATIENT_HEALTH_CARDS = 'SORT_PATIENT_HEALTH_CARDS';

export const TOGGLE_CREATE_PATIENT_HEALTH_CARD_SUCCESS_MODAL =
	'TOGGLE_CREATE_PATIENT_HEALTH_CARD_SUCCESS_MODAL';

export const SEND_PATIENT_HEALTH_CARD_REQUEST =
	'SEND_PATIENT_HEALTH_CARD_REQUEST';
export const SEND_PATIENT_HEALTH_CARD_SUCCESS =
	'SEND_PATIENT_HEALTH_CARD_SUCCESS';
export const SEND_PATIENT_HEALTH_CARD_FAIL = 'SEND_PATIENT_HEALTH_CARD_FAIL';

export const TOGGLE_SEND_PATIENT_HEALTH_CARD_SUCCESS_MODAL =
	'TOGGLE_SEND_PATIENT_HEALTH_CARD_SUCCESS_MODAL';

export const CLEAR_PATIENT = 'CLEAR_PATIENT';

// TODO: разнести редьюсер, проверить аргументы всех экшенов

export const setPatientId = (data) => ({
	type: SET_PATIENT_ID,
	payload: data,
});

const getPatientRequest = () => ({
	type: GET_PATIENT_REQUEST,
});

const getPatientSuccess = (data) => ({
	type: GET_PATIENT_SUCCESS,
	payload: data,
});

const getPatientFail = () => ({
	type: GET_PATIENT_FAIL,
});

export const getPatient = (id) => (dispatch) => {
	dispatch(getPatientRequest());

	fetch(`${API_URL}/patient/${id}`)
		.then((response) => response.json())
		.then((response) => {
			setTimeout(() => {
				dispatch(getPatientSuccess(response));
			}, 1500);
		})
		.catch(() => dispatch(getPatientFail()));
};

const getPatientHealthCardsRequest = () => ({
	type: GET_PATIENT_HEALTH_CARDS_REQUEST,
});

const getPatientHealthCardsSuccess = (data) => ({
	type: GET_PATIENT_HEALTH_CARDS_SUCCESS,
	payload: data,
});

const getPatientHealthCardsFail = () => ({
	type: GET_PATIENT_HEALTH_CARDS_FAIL,
});

export const getPatientHealthCards = (id) => (dispatch) => {
	dispatch(getPatientHealthCardsRequest());

	fetch(`${API_URL}/patient/${id}/health-cards`)
		.then((response) => response.json())
		.then((response) => {
			setTimeout(() => {
				dispatch(getPatientHealthCardsSuccess(response.data));
			}, 1500);
		})
		.catch(() => dispatch(getPatientHealthCardsFail()));
};

export const chooseBeforeAfterPhoto = (data) => ({
	type: CHOOSE_BEFORE_AFTER_PHOTO,
	payload: data,
});

export const chooseXRayPhoto = (data) => ({
	type: CHOOSE_X_RAY_PHOTO,
	payload: data,
});

const createPatientHealthCardRequest = () => ({
	type: CREATE_PATIENT_HEALTH_CARD_REQUEST,
});

const createPatientHealthCardSuccess = (healthCardName) => ({
	type: CREATE_PATIENT_HEALTH_CARD_SUCCESS,
	payload: healthCardName,
});

const createPatientHealthCardFail = () => ({
	type: CREATE_PATIENT_HEALTH_CARD_FAIL,
});

// TODO: добавить вывод модалок с ошибками
export const createPatientHealthCard = (data) => (dispatch) => {
	dispatch(createPatientHealthCardRequest());

	getPdfFromCanvas()
		.then((pdf) => {
			const { dname } = JSON.parse(localStorage.getItem('doctor'));

			const body = new FormData();
			body.append('doctor', dname);
			body.append('card', pdf, 'healthcard.pdf');

			const options = {
				method: 'POST',
				body,
			};

			return fetch(`${API_URL}/patient/${data}/health-cards`, options);
		})
		.then((response) => response.json())
		.then((response) =>
			setTimeout(
				() => dispatch(createPatientHealthCardSuccess(response.data.name)),
				1500
			)
		)
		.catch(() => dispatch(createPatientHealthCardFail()));
};

export const toggleCreatePatientHealthCardSuccessModal = () => ({
	type: TOGGLE_CREATE_PATIENT_HEALTH_CARD_SUCCESS_MODAL,
});

export const sortPatientHealthCards = (data) => ({
	type: SORT_PATIENT_HEALTH_CARDS,
	payload: data,
});

const sendPatientHealthCardRequest = () => ({
	type: SEND_PATIENT_HEALTH_CARD_REQUEST,
});

const sendPatientHealthCardSuccess = () => ({
	type: SEND_PATIENT_HEALTH_CARD_SUCCESS,
});

const sendPatientHealthCardFail = () => ({
	type: SEND_PATIENT_HEALTH_CARD_FAIL,
});

export const sendPatientHealthCard = (data) => (dispatch) => {
	dispatch(sendPatientHealthCardRequest());

	const options = {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
	};

	fetch(`${API_URL}/send-email`, options)
		.then((response) => response.json())
		.then(() => setTimeout(() => dispatch(sendPatientHealthCardSuccess()), 1500))
		.catch(() => dispatch(sendPatientHealthCardFail()));
};

export const toggleSendPatientHealthCardSuccessModal = () => ({
	type: TOGGLE_SEND_PATIENT_HEALTH_CARD_SUCCESS_MODAL,
});

export const setTreatmentPlanOrder = (data) => ({
	type: SET_TREATMENT_PLAN_ORDER,
	payload: data,
});

export const sortTreatmentPlan = (data) => ({
	type: SORT_TREATMENT_PLAN,
	payload: data,
});

export const toggleTreatmentPlanTooth = (data) => ({
	type: TOGGLE_TREATMENT_PLAN_TOOTH,
	payload: data,
});

export const clearPatient = () => ({
	type: CLEAR_PATIENT,
});
