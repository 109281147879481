import React from 'react';

import Thumbs from './Thumbs';

import ignoreUncheckedElementInCanvas from '../../../helpers/ignoreUncheckedElementInCanvas';

import './styles.less';

// TODO: наверно, надо как-то вынести в отдельный компонент этот лоадер
class Attachments extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);

		this.state = {
			files: [],
		};
	}

	onChange({ target }) {
		const { files } = this.state;

		this.setState({
			files: [...target.files]
				.map((file) => ({
					...file,
					preview: URL.createObjectURL(file),
				}))
				.concat(files),
		});
	}

	render() {
		const { files } = this.state;

		return (
			<div
				className="health-card__section attachments"
				{...ignoreUncheckedElementInCanvas(files.length)}
			>
				<h3>Дополнительные файлы</h3>
				<input
					type="file"
					accept="image/*"
					multiple
					onChange={this.onChange}
					data-html2canvas-ignore="true"
				/>
				<Thumbs attachments={files} />
			</div>
		);
	}
}

export default Attachments;
