import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
	getPatientHealthCards,
	setPatientId,
	sortPatientHealthCards,
} from '../../actions/healthCards';

import Input from '../../components/shared/Input';
import Button from '../../components/shared/Button';

import HealthCards from './HealthCards';

import './styles.less';

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.onPatientIdChange = this.onPatientIdChange.bind(this);
		this.onFindPatientHealthCardsClick = this.onFindPatientHealthCardsClick.bind(
			this
		);
	}

	onPatientIdChange({ value }) {
		this.props.setPatientId(value);
	}

	onFindPatientHealthCardsClick() {
		const { patientId } = this.props;

		this.props.getPatientHealthCards(patientId);
	}

	render() {
		const {
			patientId,
			isGettingPatientHealthCards,
			isGotPatientHealthCards,
			patientHealthCards,
			patientHealthCardsSortingDirection,
		} = this.props;

		const isCreationCardAvailable =
			isGotPatientHealthCards && patientHealthCards !== null;

		return (
			<div className="container">
				<main className="health-cards-wrapper">
					<div className="health-cards">
						<h2 className="health-cards__header">Карты здоровья</h2>
						<div className="health-cards__search">
							{!isGettingPatientHealthCards && (
								<Input
									className="health-cards__input"
									type="text"
									value={patientId}
									name="patientId"
									onChange={this.onPatientIdChange}
								/>
							)}
							<Button
								className="button--primary health-cards__button health-cards__button--search"
								isLoading={isGettingPatientHealthCards}
								onClick={this.onFindPatientHealthCardsClick}
								disabled={!patientId.length}
							>
								Найти клиента
							</Button>
							{isCreationCardAvailable && (
								<Link
									className="button button--primary health-cards__button health-cards__button--create"
									to={`/сreate-health-card?patientId=${patientId}`}
								>
									Создать карту
								</Link>
							)}
						</div>
					</div>
					<HealthCards
						isGotPatientHealthCards={isGotPatientHealthCards}
						patientHealthCards={patientHealthCards}
						patientId={patientId}
						sortPatientHealthCards={this.props.sortPatientHealthCards}
						patientHealthCardsSortingDirection={patientHealthCardsSortingDirection}
					/>
				</main>
			</div>
		);
	}
}

// TODO: мб вынести patientId в отдельный reducer
const mapStateToProps = (state) => ({
	patientId: state.healthCards.patientId,
	isGettingPatientHealthCards: state.healthCards.isGettingPatientHealthCards,
	isGotPatientHealthCards: state.healthCards.isGotPatientHealthCards,
	patientHealthCards: state.healthCards.patientHealthCards,
	patientHealthCardsSortingDirection:
		state.healthCards.patientHealthCardsSortingDirection,
});

const mapDispatchToProps = (dispatch) => ({
	getPatientHealthCards: (data) => dispatch(getPatientHealthCards(data)),
	setPatientId: (data) => dispatch(setPatientId(data)),
	sortPatientHealthCards: (data) => dispatch(sortPatientHealthCards(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
