import React from 'react';
import classNames from 'classnames';

import ignoreUncheckedElementInCanvas from '../../../helpers/ignoreUncheckedElementInCanvas';

import './styles.less';

class TreatmentPlans extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isBlockIgnoredInCanvas: false,
		};
	}

	onVisibilitySwitcherChangeHandler() {
		const { isBlockIgnoredInCanvas } = this.state;

		this.setState({
			isBlockIgnoredInCanvas: !isBlockIgnoredInCanvas,
		});
	}

	onTreatmentPlanToggleToothHandler({ id, tooth }) {
		this.props.onTreatmentPlanToggleToothHandler({
			id,
			toothId: tooth.id,
		});
	}

	onTreatmentPlanSetOrderHandler({ id, tooth }) {
		return ({ target }) =>
			this.props.onTreatmentPlanSetOrderHandler({
				id,
				toothId: tooth.id,
				sort: Number(target.value),
			});
	}

	render() {
		const { treatmentPlans = [] } = this.props;

		if (!treatmentPlans.length) {
			return (
				<div
					className="health-card__section treatment-plans"
					data-html2canvas-ignore="true"
				>
					<h3>Планы лечений</h3>
					<p>У этого пациента нет планов лечений.</p>
				</div>
			);
		}

		const { isBlockIgnoredInCanvas } = this.state;

		return (
			<div
				className="health-card__section treatment-plans"
				{...ignoreUncheckedElementInCanvas(isBlockIgnoredInCanvas)}
			>
				<label>
					Планы лечений
					<input
						type="checkbox"
						onChange={() => this.onVisibilitySwitcherChangeHandler()}
					/>
				</label>
				{treatmentPlans.map(({ id, created_at, doctor, teeth, cost }) => (
					<div className="treatment-plans__plan plan" key={id}>
						<div className="plan__details">
							<span className="plan__date">
								<b>Дата:</b> {new Date(created_at).toLocaleDateString()}
							</span>
							<span>
								<b>Врач:</b> {doctor}
							</span>
						</div>
						<table className="plan__table">
							<thead>
								<tr>
									<th>Этап</th>
									<th>Зуб</th>
									<th>Название услуги</th>
									<th>Цена</th>
									<th>Количество</th>
									<th>Стоимость</th>
								</tr>
							</thead>
							<tbody>
								{teeth.map((tooth) => (
									<tr
										className={classNames('plan__row selectable', {
											'plan__row--checked': tooth.checked,
										})}
									>
										<td>
											<input
												type="number"
												value={tooth.sort}
												onBlur={() => this.props.onTreatmentPlanSortHandler(id)}
												onChange={this.onTreatmentPlanSetOrderHandler({ id, tooth })}
											/>
										</td>
										<td>{tooth.id}</td>
										<td
											className="left-align"
											onClick={() => this.onTreatmentPlanToggleToothHandler({ id, tooth })}
										>
											{tooth.service}
										</td>
										<td>{tooth.price}</td>
										<td>{tooth.amount}</td>
										<td>{tooth.cost}</td>
									</tr>
								))}
							</tbody>
						</table>
						<div>
							<span>
								<b>Итоговая сумма:</b> {cost}р
							</span>
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default TreatmentPlans;
