import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';

const PrivateRoute = (props) => {
	const { children, isAuthorized, location, ...rest } = props;

	// TODO: переделать в функцию получения to
	if (!isAuthorized) {
		return (
			<Route {...rest}>
				<Redirect to={{ pathname: '/login', state: { from: location } }} />
			</Route>
		);
	}

	return <Route {...rest}>{children}</Route>;
};

PrivateRoute.propTypes = {
	children: PropTypes.element.isRequired,
	isAuthorized: PropTypes.bool.isRequired,
	location: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
	isAuthorized: state.auth.isAuthorized,
});

export default connect(mapStateToProps, null)(withRouter(PrivateRoute));
